<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>分类名称：</label>
            <el-input v-model.trim="params.title" placeholder="分类名称"></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-row class="m-bottom-10" type="flex" justify="space-between">
          <div></div>
          <div class="right-btn">
            <el-button @click="categoryVisible = true; action = 'add'" class="bg-gradient">新建分类</el-button>
          </div>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="title" label="分类名称"></el-table-column>
          <el-table-column prop="relationCategoryName" label="关联分类"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">{{ scope.row.type | filterType }}</template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{ scope.row.status | filterStatus }}</template>
          </el-table-column>
          <el-table-column prop="quantity" label="在售商品数"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.sort !== 1" @click="handleMove(scope.row.id, 0)" type="text">上移</el-button>
              <el-button v-if="scope.row.sort !== tableData.length" @click="handleMove(scope.row.id, 1)" type="text">下移</el-button>
              <el-button @click="handleHide(scope.row.id, '1')" v-if="scope.row.status === '0'" type="text">显示</el-button>
              <el-button @click="handleHide(scope.row.id, '0')" v-else type="text">
                <el-link type="danger">隐藏</el-link>
              </el-button>
              <el-button v-if="scope.row.type === '2'" @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="params.limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="新建分类" :visible.sync="categoryVisible" width="550px" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="分类名称" prop="title">
          <el-input class="width-280" v-model="ruleForm.title" placeholder="分类名称"></el-input>
        </el-form-item>
        <div v-if="!(type === '1' && action === 'edit')">
          <el-form-item label="选择商品" prop="productIdList">
            <el-button @click="openSelectedGoods" >选择商品</el-button>
            <span style="color: #F56C6C">{{ ruleForm.productIdList.length === 0 ? '未选择' : '' }}</span>
          </el-form-item>
          <el-form-item label="已选数量">
            <span>{{ ruleForm.productIdList.length }}</span>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button @click="handleSave" type="primary">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="goodsVisible" width="550px">
      <el-row class="m-bottom-20">
        <label>商品名称：</label>
        <el-input v-model="name" clearable class="width-200 m-right-5"></el-input>
        <el-button @click="getProductList" type="primary">搜索</el-button>
      </el-row>
      <div class="goods-box">
        <el-table ref="goodsForm" :data="goodsData" :stripe="true" border fit
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="商品ID"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button @click="goodsVisible = false">取消</el-button>
        <el-button @click="saveCheckGoods" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, addCategory, moveCategory, delCategory,
  getProductPage, updateStatus, getCategoryProductPage } from "@/api/shop/conf/classify";
import { mapState } from 'vuex'
export default {
  name: 'classify',
  data() {
    const validProduct = (rule, value, callback) => {
      if (this.type === '2' && value.length === 0) callback(new Error('商品不能为空！'))
      else callback()
    }
    return {
      crumbs: new Map([
          ['特惠商城'], ['页面管理'], ['分类页管理']
      ]),
      loading: true,
      tableData: [],
      params: {
        limit: 0,
        page: 1,
      },
      total: 0,
      categoryVisible: false,
      ruleForm: {
        id: '',
        type: '2',
        status: '1',
        title: '',
        categoryId: '',
        productIdList: []
      },
      rules: {
        title: [{ required: true, message: '分类名称不能为空！' }],
        productIdList: [{ required: true, message: '商品数量不能为空！' }]
      },
      action: 'add',
      goodsVisible: false,
      name: '',
      goodsData: [],
      productIdList: [],
      type: '2'
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.params.limit = sessionStorage.getItem('pageSize') * 1;
    this.getCategoryList()
    this.getProductList()
  },
  methods: {
    // 获取分类列表
    getCategoryList() {
      const params = { ...this.params, hotelId: this.hotelInfo.id };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, total, records }) => {
        if (!success) return
        this.total = total
        this.loading = false
        this.tableData = records
      })
    },
    // 搜索分类
    handleQuery(bool) {
      this.params.page = 1
      if (bool) return this.getCategoryList()
      this.params.title = ''
    },
    // 改变每页数
    changePageNum(num) {
      this.params.limit = num
      this.getCategoryList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.params.page = num
      this.getCategoryList()
    },
    // 获取商品列表`
    getProductList() {
      const query = `?limit=99999&page=1`;
      const params = { name: this.name, hotelId: this.hotelInfo.id, status: 'PUTAWAY' };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getProductPage(query, params).then(({ success, records }) => {
        if (success) this.goodsData = records
      })
    },
    // 处理多选商品
    handleSelectionChange(val) {
      this.productIdList = val.map(i => i.id)
    },
    // 保存选中商品
    saveCheckGoods() {
      this.goodsVisible = false
      this.ruleForm.productIdList = [ ...this.productIdList ]
    },
    // 编辑分类
    handleEdit(row) {
      this.categoryVisible = true
      this.action = 'edit'
      this.type = row.type
      for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k])
      this.getCategoryProduct(row)
    },
    // 根据分类获取商品
    getCategoryProduct({ type, id }) {
      const params = Object.create(null)
      if (type === '1') { params.categoryType = 1; params.categoryId = id }
      else { params.categoryType = 2; params.menuId = id }
      getCategoryProductPage(params).then(({ success, records }) => {
        if (!success) return
        this.productIdList = records.map(i => i.id)
        this.ruleForm.productIdList = [ ...this.productIdList ]
      })
    },
    // 打开商品会话框
    openSelectedGoods() {
      this.goodsVisible = true
      this.action ==='edit' && this.productIdList.forEach(i => {
        this.$nextTick(() => this.$refs.goodsForm.toggleRowSelection(this.goodsData.find(r => i === r.id)))
      })
    },
    // 保存分类
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = { ...this.ruleForm };
        for (const k in params) {
          if (!params[k] || params[k].length === 0) delete params[k]
        }
        if (this.action === 'add') {
          delete params.id
          params.hotelId = this.hotelInfo.id
          params.companyId = this.hotelInfo.storeId
        }
        addCategory(params, this.action).then(({ success }) => {
          if (!success) return;
          this.getCategoryList()
          this.handleClose()
          this.$message({ message: '保存成功', type: 'success' })
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.categoryVisible = false
      this.ruleForm.title = ''
      this.ruleForm.categoryId = ''
      this.ruleForm.productIdList.length = 0
      this.$refs.goodsForm && this.$refs.goodsForm.clearSelection();
    },
    // 处理排序
    handleMove(id, step) {
      const params = { id, step }
      moveCategory(params).then(({ success }) => {
        if (!success) return;
        this.getCategoryList()
        this.$message({ message: '排序成功！', type: 'success' })
      })
    },
    // 处理隐藏
    handleHide(id, status) {
      this.$confirm(`是否确认${ status === '0' ? '隐藏' : '显示' }该条目？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        const params = { id, status };
        updateStatus(params).then(({ success }) => {
          if (!success) return;
          this.getCategoryList()
          this.$message({ message: `${ status === '0' ? '隐藏' : '显示' }成功！`, type: 'success' })
        })
      }).catch(_ => {})
    },
    // 删除分类
    handleDel(id) {
      this.$confirm('是否确认删除该条目，小程序首页将不会在显示？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        delCategory(`/${ id }`).then(({ success }) => {
          if (!success) return;
          this.getCategoryList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getCategoryList()
        this.getProductList()
      }
    }
  },
  filters: {
    filterStatus(val) {
      const obj = { '0': '隐藏', '1': '显示' };
      return obj[val]
    },
    filterType(val) {
      const obj = { '1': '分类', '2': '自建' };
      return obj[val]
    }
  }
}
</script>
<style scoped lang="scss">
.cont{
  .el-form{
    padding: 0 30px;
    ::v-deep .el-form-item__error{ margin-left: 78px }
  }
  .goods-box{
    height: 500px; overflow-y: auto;
  }
}
</style>
