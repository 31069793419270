import request from "@/api/service";
import qs from 'qs'

const api = {
   page: '/goods/categoryMenu/getPage',
   add: '/goods/categoryMenu/add',
   edit: '/goods/categoryMenu/edit',
   move: '/goods/categoryMenu/move',
   delete: '/goods/categoryMenu/delete',
   productPage: '/goods/productInfo/getPage',
   updateStatus: '/goods/categoryMenu/updateStatus',
   categoryProductPage: '/goods/productInfo/getCategoryProductList'
}

export function getPage(params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function addCategory(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function moveCategory(params) {
   return request({
      url: api.move,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function delCategory(query) {
   return request({
      url: api.delete + query,
      method: 'POST',
   })
}

export function getProductPage(query, params = {}) {
   return request({
      url: api.productPage + query,
      method: 'POST',
      data: params
   })
}

export function getCategoryProductPage(params) {
   return request({
      url: api.categoryProductPage,
      method: 'POST',
      data: params
   })
}

export function updateStatus(params) {
   return request({
      url: api.updateStatus,
      method: 'POST',
      data: params
   })
}
